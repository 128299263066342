.custom-table-one-container {
  width: 100%;
  
  overflow-y: auto;   /* Enable vertical scrolling */
  border-radius: 5px 5px 0 0;
}

.custom-table-one {
  border-collapse: collapse;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  table-layout: fixed;   /* Ensure consistent column width */
  width: 100%;           /* Ensure the table takes full width */
}

.custom-table-one thead {
  position: sticky;
  top: 0;
  background-color: #31597580;
  color: #315975;
  z-index: 1;            /* Ensure header stays above scrolling content */
}

.custom-table-one thead tr {
  text-align: left;
  font-weight: bold;
}

.custom-table-one th,
.custom-table-one td {
  padding: 12px 15px;
  word-wrap: break-word;  /* Ensures content wraps within cells */
}

.custom-table-one tbody tr {
  border-bottom: 1px solid #dddddd;
}

.custom-table-one tbody tr:last-of-type {
  border-bottom: 2px solid #31597580;
}

.custom-table-one tbody tr.active-row {
  font-weight: bold;
  color: #315975;
}
