* Table */ .gridlines {
    color: black;
    border-collapse: collapse;
    width: 100%;
}

.gridlines-container {
    margin-bottom: 20px;
    padding: 10px;
    /* overflow-x: auto;  */
}

.gridlines th,
.gridlines td {
    border: 1px solid #00000070;
    padding: 3px;
    min-width: 40px;
    position: relative;
    /* Add a relative position to allow pseudo-element positioning */
    white-space: unset;
    /* Prevents wrapping to keep text on a single line */
    word-break: break-word;
    /* Breaks long words to prevent horizontal overflow */
    height: 25px;
    overflow: hidden;
    /* Handles overflow of long content */
}

.gridlines th {
    background-color: #f2f2f2;
}

.table-h1 {
    font-weight: 700;
    background-color: darkgray;
}

.table-h2 {
    font-weight: 600;
    background-color: darkgray;
}

.table-h3 {
    font-weight: 500;

}

.table-no {
    text-align: center;
}